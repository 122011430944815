<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "PasteWagons",
    props: {
        wagons: {
            type: Array,
            required: true
        }
    },
    computed: {
        wagonsComputed() {
            return this.wagons.map((wagon) => {
                let wagon_name = wagon.wagon === null ? wagon.wagon : wagon.wagon.name
                return {
                    id: wagon.id,
                    wagon_name: wagon_name,
                    containers: wagon.train_wagon_containers.map(wagon => wagon.container.name)
                }
            })
        },
    },
})
</script>

<template>
    <div id="PasteWagonsModal" class="modal fade" tabindex="-1" aria-hidden="true" style="display: none;">
        <div class="modal-dialog modal-lg">
            <div class="modal-content border-0">
                <div class="modal-header border-bottom p-3">
                    <h5 class="modal-title">
                        Paste Wagons
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-4">
                            <label class="form-label">Wagons</label>
                            <textarea class="form-control mb-3"
                                      :value="wagonsComputed.map(w => w.wagon_name).join('\n')"
                                      :rows="wagonsComputed.length"
                            >
                            </textarea>
                        </div>
                        <div class="col-8">
                            <label class="form-label">Containers</label>
                            <textarea class="form-control mb-3"
                                      :value="wagonsComputed.map(obj => obj.containers.join(', ')).join('\n')"
                                      :rows="wagonsComputed.length"
                            >
                    </textarea>
                        </div>
                    </div>
                    <div class="text-end">
                        <b-button variant="success">Submit</b-button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<style scoped>

</style>