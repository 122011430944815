<script>
import {defineComponent} from 'vue'
import axios from "axios";
import Swal from "sweetalert2";
import PasteWagonsModal from "../modals/PasteWagons.vue"
import lottie from "@/components/widgets/lottie.vue";
import spxnqpau from "@/components/widgets/spxnqpau.json";

export default defineComponent({
  name: "MainTab",
  components: {lottie, PasteWagonsModal},
  data() {
    return {
      quantity: 1,
      platform_type: '80',

      is_loading_wagons: true,

      container_name: '',
      container_type: '40',
      container_wagon_id: null,

      wagons: [],
      delete_wagon: {
        id: null,
        is_confirmed: false
      },
      selected_wagons: [],

      no_wagon_widget: {animationData: spxnqpau},
    }
  },
  computed: {
    train_slug() {
      return this.$route.params.train_slug
    }
  },
  methods: {
    async createWagons() {
      let response = await axios.post(`/train/create/${this.train_slug}/count/`, {
        count: this.quantity,
        platform_type: this.platform_type
      })
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      if (response.status === 201) {
        await this.getWagons()
        document.querySelector('#WagonCreateModal .btn-close').click()
        this.quantity = 1
        await Toast.fire({
          icon: 'success',
          title: 'Wagons created successfully',
        })
      } else {
        await Toast.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    },
    async createContainer() {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      if (this.container_name.trim().length === 0) {
        await Toast.fire({
          icon: 'warning',
          text: 'Please enter container name!',
        })
        return
      }
      let response = await axios.post(`/train/container/${this.container_wagon_id}/create/`, {
        name: this.container_name,
        container_type: this.container_type
      })
      if (response.status === 201) {
        document.querySelector('#ContainerCreateModal .btn-close').click()
        await Toast.fire({
          icon: 'success',
          text: 'Container created successfully!',
        })
        await this.getWagons()
      } else {
        await Toast.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      }
    },
    async getWagons() {
      this.is_loading_wagons = true
      let response = await axios.get(`/train/${this.train_slug}/`)
      if (response.status === 200 && response.data) {
        this.wagons = response.data.wagons
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        await Toast.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'We could not load your wagons!',
        })
      }
      this.is_loading_wagons = false
    },
    async editExistingWagon(wagon) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      if (wagon.wagon.name.trim().length === 0) {
        await Toast.fire({
          icon: 'warning',
          text: 'Please enter wagon name!',
        })
        return
      }
      let response = await axios.put(`/train/wagon/${wagon.id}/update/`, {
        wagon: wagon.wagon.name,
      })
      await Toast.fire({
        icon: response.status === 200 ? 'success' : 'error',
        text: response.status === 200 ? 'Wagon updated successfully!' : 'Something went wrong!',
      })
      await this.getWagons()
    },
    async editNewWagon(wagon) {
      const Toast = Swal.mixin({
        toast: true,
        position: 'top',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
      })
      if (!wagon.new_name) {
        await Toast.fire({
          icon: 'warning',
          text: 'Please enter wagon name!',
        })
      } else {
        let response = await axios.put(`/train/wagon/${wagon.id}/update/`, {
          wagon: wagon.new_name,
        })
        await Toast.fire({
          icon: response.status === 200 ? 'success' : 'error',
          text: response.status === 200 ? 'Wagon updated successfully!' : 'Something went wrong!',
        })
        await this.getWagons()
      }
    },
    async confirmdDeleteWagon(wagon) {
      this.delete_wagon.id = wagon.id
      this.delete_wagon.is_confirmed = true
    },
    async deleteWagon(wagon) {
      if (this.delete_wagon.id !== wagon.id) {
        this.delete_wagon.id = wagon.id
        this.delete_wagon.is_confirmed = true
      } else if (this.delete_wagon.id === wagon.id) {
        let response = await axios.delete(`/train/wagon/${wagon.id}/delete/`)
        const Toast = Swal.mixin({
          toast: true,
          position: 'top',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
        })
        await this.getWagons()
        await Toast.fire({
          icon: response.status === 204 ? 'success' : 'error',
          text: response.status === 204 ? 'Wagon deleted successfully!' : 'Something went wrong!',
        })
        this.delete_wagon.id = null
        this.delete_wagon.is_confirmed = false
      }
    },

    selectWagon(wagon) {
      this.selected_wagons.find(s => s.id === wagon.id)
          ? this.selected_wagons = this.selected_wagons.filter(s => s.id !== wagon.id)
          : this.selected_wagons.push(wagon)
    },
    selectAllWagons() {
      if (this.selected_wagons.length === this.wagons.length) {
        this.selected_wagons = []
      } else {
        this.selected_wagons = this.wagons
      }
    }
  },
  mounted() {
    this.getWagons()
  }
})
</script>

<template>
  <div class="tab-pane fade"
       :class="{'show active': $route.name === 'train_details_main'}"
       id="main-tab" role="tabpanel">
    <div class="w-100">
      <b-card no-body>
        <b-card-body class="d-flex justify-content-start">
          <div class="d-flex justify-content-between align-items-center w-100">
            <div>
              <div class="form-check" v-if="wagons.length>0">
                <input @click="selectAllWagons()" class="form-check-input" type="checkbox"
                       :checked="this.selected_wagons.length === wagons.length">
                <label class="form-check-label">
                  Select All Wagons
                </label>
              </div>
            </div>
            <div>
              <b-button v-if="selected_wagons.length > 0" variant="outline-success"
                        data-bs-target="#PasteWagonsModal"
                        data-bs-toggle="modal">
                Paste Wagons
                <span class="badge bg-success ms-1">{{ selected_wagons.length }}</span>
              </b-button>
              <b-button class="ms-3" variant="outline-success" data-bs-target="#WagonCreateModal"
                        data-bs-toggle="modal">Create Wagon
              </b-button>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </div>
    <div class="row gy-5 align-items-end mb-4" v-if="!is_loading_wagons">
      <div v-for="(wagon, index) in wagons" :key="wagon"
           class="col-3" :class="index + 1 === wagons.length ? '' : ''"
      >
        <div class="row w-100 mx-0 justify-content-center">
          <div v-for="container in wagon.train_wagon_containers" :key="container"
               class="col px-1">
            <b-button size="sm" class="w-100"
                      :variant="container.container.type.includes('40') ? 'primary' : 'info'">
              <VTooltip>
                <span> {{ container.container.name }} </span>
                <template #popper>
                  <div class="table-responsive">
                    <table class="table">
                      <thead>
                      <tr class="text-light">
                        <th style="min-width: 150px">Name</th>
                        <th>Type</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr class="text-light">
                        <td>{{ container.container.name }}</td>
                        <td>
                          {{
                            container.container.type === '' ? '--' : container.container.type
                          }}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </template>
              </VTooltip>
            </b-button>
          </div>
        </div>
        <b-card no-body class="mb-0">
          <b-card-body :class="[index+1 < wagons.length && 'train-connection-icon']"
                       class="p-0 m-0">
            <div class="row w-100 mx-0 align-items-center justify-content-between">
              <div class="col-2">
                <div class="form-check">
                  <input @click="selectWagon(wagon)"
                         class="form-check-input" type="checkbox"
                         :checked="this.selected_wagons.find(s => s.id === wagon.id)">
                </div>
              </div>
              <div class="col-6">
                <input v-if="wagon.wagon === null" type="text"
                       placeholder="Wagon name"
                       class="text-center form-control form-control-sm border-0 py-2"
                       v-model="wagon.new_name"
                       v-on:keyup.enter="editNewWagon(wagon)"
                >
                <input v-else type="text"
                       placeholder="Wagon name"
                       class="text-center form-control form-control-sm border-0 py-2"
                       v-model="wagon.wagon.name"
                       v-on:keyup.enter="editExistingWagon(wagon)"
                >
              </div>
              <div class="col-4 text-end">
                <b-dropdown right variant="light" :text="wagon.platform_type" auto-close="outside"
                            size="sm">
                  <b-dropdown-item>
                    <div @click="container_wagon_id = wagon.id" class="w-100"
                         data-bs-target="#ContainerCreateModal"
                         data-bs-toggle="modal">
                      Add container
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item @click="deleteWagon(wagon)">
                                    <span v-if="delete_wagon.id !== wagon.id"
                                          class="fw-medium">Delete wagon</span>
                    <div v-else-if="delete_wagon.id === wagon.id"
                         class="d-flex justify-content-between align-items-center">
                      <span class="text-danger fw-medium">Confirm</span>
                      <i class="bx bx-check fs-17 me-1 text-danger"></i>
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </div>
    </div>
    <div class="py-4 text-center">
      <b-spinner v-if="is_loading_wagons" label="Loading..."></b-spinner>

      <div v-else-if="wagons.length === 0" class="text-center">
        <lottie
            colors="primary:#405189,secondary:#08a88a"
            :options="no_wagon_widget"
            :height="150"
            :width="150"
        />
        <span class="fs-3">No Wagons</span>
      </div>

    </div>
  </div>


  <div id="WagonCreateModal" class="modal fade" tabindex="-1" aria-hidden="true" style="display: none;">
    <div class="modal-dialog">
      <div class="modal-content border-0">
        <div class="modal-header border-bottom p-3">
          <h5 class="modal-title">
            Create Wagons
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form class="modal-body" @submit.prevent="createWagons">
          <div class="row mb-4">
            <div class="col-8">
              <label class="form-label text-dark">Wagon's Quantity</label>
              <input v-model="quantity" type="number" class="form-control"
                     placeholder="Wagon's quantity">
            </div>
            <div class="col-4">
              <label class="form-label text-dark">Platform Type</label>
              <select class="form-select" v-model="platform_type">
                <option value="40">40</option>
                <option value="60">60</option>
                <option value="80">80</option>
              </select>
            </div>
          </div>
          <div class="pt-3 text-end">
            <b-button type="submit" variant="success">Create</b-button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div id="ContainerCreateModal" class="modal fade" tabindex="-1" aria-hidden="true" style="display: none;">
    <div class="modal-dialog">
      <div class="modal-content border-0">
        <div class="modal-header border-bottom p-3">
          <h5 class="modal-title">
            Create Container
          </h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <form class="modal-body" @submit.prevent="createContainer">
          <div class="row mb-4">
            <div class="col-8">
              <label class="form-label text-dark">Container Name</label>
              <input v-model="container_name" type="text" class="form-control"
                     placeholder="Container name">
            </div>
            <div class="col-4">
              <label class="form-label text-dark">Container Type</label>
              <select class="form-select" v-model="container_type">
                <option value="20">20</option>
                <option value="20HC">20HC</option>
                <option value="40">40</option>
                <option value="40HC">40HC</option>
              </select>
            </div>
          </div>
          <div class="pt-3 text-end">
            <b-button type="submit" variant="success">Create</b-button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <PasteWagonsModal :wagons="selected_wagons"/>
</template>

<style scoped>
.train-connection-icon {
  position: relative;
}

.train-connection-icon::after {
  content: '---';
  position: absolute;
  top: 25%;
  right: -23px;
  font-weight: bold;
  color: black;
}
</style>